<template>
    <div @click="loginWithOneId()" class="btn-oneid">
        <img src="../../assets/one-id.svg" alt="one-id" />
        <p v-if="loadingProvider === 'one_id'">
            <b-spinner class="primary-text loader-spinner" small></b-spinner>
        </p>
        <p v-else>{{ $l("Platon.sign_in_with_one_id", "OneID орқали кириш") }}</p>
    </div>
</template>
<script>
import LoginMixin from "@Platon/components/login/LoginMixin"

export default {
    name: "OneIdProvider",
    mixins: [LoginMixin],

    mounted() {
        this.loginValidation()
    }
}
</script>

<style scoped lang="scss">
.btn-oneid {
    display: flex;
    cursor: pointer;
    padding: 19px 12px;
    border-radius: 4px;
    align-items: center;
    background: #4725c1;
    position: relative;
    justify-content: center;
    &:hover {
        background: #4021b2;
    }
    &:active {
        background: #371c9b;
    }
    img {
        left: 14px;
        width: 75px;
        position: absolute;
    }
    p {
        margin: 0;
        width: 100%;
        color: white;
        text-align: center;
        position: absolute;
    }
}
</style>
